import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Select from 'Components/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { AccessTime } from '@material-ui/icons';

const styles = theme => ({
  coverDateTime: {
    '& input.e-control.e-timepicker': {
      color: theme.palette.grey[700],
      paddingBottom: 6,
      paddingTop: 6,
      lineHeight: '19px', //line height has to be `Xpx`
      fontSize: 16,
      '&.e-disabled': {
        cursor: 'default',
      },
      '&::selection': {
        background: theme.palette.action.inputHighlight,
        textShadow: 'none',
        color: theme.palette.common.black,
      },
    },
    '&.e-float-input': {
      marginBottom: 0,
      '& input:valid ~ label.e-float-text': {
        top: -11,
      },
    },
    '& label.e-float-text': {
      fontFamily: `${theme.typography.fontFamily} !important`,
    },
    '& label.e-label-bottom': {
      fontSize: `16px !important`,
    },
    '& td.e-selected span.e-day': {
      background: `${theme.palette.primary.dark} !important`, //currently selected day
    },
    '& .e-btn': {
      color: `${theme.palette.primary.dark} !important`, //today button
    },
  },
  noErrors: {
    '& label.e-float-text': {
      color: `${theme.palette.grey[200]} !important`,
    },
    '& .e-input-focus label.e-float-text': {
      color: theme.palette.primary.dark + '!important',
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.primary.dark} !important`,
    },
    '& .e-icons': {
      color: `${theme.palette.grey[300]} !important`, //clickable calendar and clear icon
    },
    '& .e-active': {
      color: theme.palette.primary.dark + '!important',
    },
  },
  errors: {
    '& $coverDateTime': {
      borderBottomColor: `${theme.palette.error.main} !important`,
    },
    '& label.e-float-text': {
      color: `${theme.palette.error.main} !important`,
    },
    '& .e-float-line::before, & .e-float-line::after': {
      background: `${theme.palette.error.main} !important`, //the
    },
    '& .e-time-icon': {
      color: `${theme.palette.error.main} !important`,
    },
  },
  pickContainer: {
    flexGrow: 1,
    margin: '0px 16px 8px',
  },
});

class CoverTimePicker extends Component {

  state = { timeOptions: [] };

  componentDidMount() {
    this.generateTimeOptions();
  }

  generateTimeOptions() {
    var timeOptions = [];

    for (var hours = 0; hours < 24; hours++) {
      for (var minutes = 0; minutes < 60; minutes += 30) {
        var hoursString = String(hours);
        var amPm = 'AM';

        if (hours >= 12) {
          amPm = 'PM';
        }

        if (hours > 12) {
          hoursString = String(hours - 12);
        }
        else if (hours === 0) {
          hoursString = '12';
        }

        timeOptions.push(`${hoursString}:${String(minutes).padStart(2, '0')} ${amPm}`);
      }
    }

    this.setState({ timeOptions });
  }

  onTimeChange = value => {
    if (!this.props.onFieldChange) {
      console.error(`no onFieldChange event for ${this.props.fieldName || this.props.label}`);

      return;
    }

    this.props.onFieldChange(value);
  }

  render() {
    const {
      value,
      className,
      label,
      name,
      classes,
      minTime,
      maxTime,
      minWidth,
      disabled,
      error,
      ...rest
    } = this.props;

    const { timeOptions } = this.state;

    const style = minWidth ? { minWidth } : undefined;

    return (
      <div className={classNames(classes.pickContainer, className, error ? classes.errors : classes.noErrors)} style={style}>
        <Select
          disabled={disabled}
          value={value}
          onFieldChange={this.onTimeChange}
          icon={AccessTime}
          {...rest}
        >
          {
            timeOptions && timeOptions.map(time =>
              <MenuItem key={time} value={time}>{time}</MenuItem>
            )
          }
        </Select>
      </div>
    );
  }
}

export default withStyles(styles)(CoverTimePicker);

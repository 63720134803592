import React, { Component } from 'react';
import { GridColumn } from '@progress/kendo-react-grid';
import ODataGrid from 'Components/Grid/ODataGrid';
import { connect } from 'react-redux';
import {
  saveOrders,
  getOrders,
  getOrderColumns,
  saveOrderColumns,
  reorderOrderColumns,
} from 'actions/order';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import dropdownFilterCell from 'Components/Grid/dropdownFilterCell';
import GridToolBar from 'Components/Grid/GridToolBar';
import SimpleDialog from 'Components/SimpleDialog';
import { ordersSelected } from 'actions/api';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { getContacts } from 'actions/contact';
import { getStatuses } from 'actions/settings';
import { getLocations } from 'actions/location';
import OrderWizard from 'orders/wizard';
import {
  DirectionsWalk as Pickup,
  DirectionsCar as Delivery,
} from '@material-ui/icons';
import HighlightButton from 'Components/Buttons/HighlightButton';
import moment from 'moment';
import IOrder from 'models/IOrder';
import IContact from 'models/IContact';
import { getStateODataSettings } from '../actions/settings'; // this should replace page size

const OrderWizardAny = OrderWizard as any;

interface IOrderExtended extends IOrder {
  isEditable: boolean,
  isQuickAdd: boolean,
  selected: boolean,
}

interface IProps {
  orders: IOrderExtended[],
  isLoading: boolean,
  wizardOpen: boolean,
  contactsData: any,
  columns: any,
  searchText: any,
  currentLocation: any,
  getContacts: any,
  saveOrders: any,
  getOrders: any,
  ordersSelected: any,
  getOrderColumns: any,
  saveOrderColumns: any,
  getLocations: any,
  reorderOrderColumns: any,
  getStatuses: any,
  getStateODataSettings: any,
  statuses: any,
}

interface IState {
  selectedIds: any,
  includeDelivery: boolean,
  includePickup: boolean,
  isFilterable: boolean,
  isEditable: boolean,
  isGridToggled: boolean,
  isGridOrderWizardOpen: boolean,
  salesRepsMap: any,
  orders: IOrderExtended[],
  contactsMap: any,
  contacts: any,
  salesReps: any,
  columns: any,
  statusMap: any,
  statuses: any[],
  sort: ISort[],
  filterId: any,
  globalFilter: any,
  locationsFilterCell: any,
  isQuickAdding: boolean,
  orderTypeFilterCell: any,
  createdByNameFilterCell: any,
  orderStatusesFilterCell: any,
}

interface ISort {
  field: string,
  dir: string,
}

class OrderGrid extends Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedIds: {},
      includeDelivery: true,
      includePickup: true,
      isFilterable: false,
      isEditable: false,
      isGridToggled: false,
      isGridOrderWizardOpen: false,
      salesRepsMap: {},
      orders: [] as IOrderExtended[],
      contactsMap: {},
      contacts: [],
      salesReps: [],
      columns: [{
        field: 'Loading...',
        title: 'Loading...',
        isVisible: true,
      }],
      statusMap: {},
      statuses: [],
      sort: [{ field: 'id', dir: 'desc' }],
      filterId: JSON.parse(localStorage.getItem('filterId')) || 4,
      globalFilter: null,
      locationsFilterCell: null,
      isQuickAdding: false,
      orderTypeFilterCell: null,
      createdByNameFilterCell: null,
      orderStatusesFilterCell: null,
    };

    this.linkOptions = {
      url: (order: IOrder) => `/orders/${order.id}`,
      text: (order: IOrder) => order.id,
    };
    this.okDialog = React.createRef();
  }

  linkOptions: any;
  okDialog: any;
  contactsFilterCell: any;

  customFilters = [
    {
      id: 2, name: 'Today\'s Orders',
      filters: [] as any[],
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'requestedDateTime',
            operator: 'gt',
            value: moment().startOf('day').utc().toDate(),
          },
          {
            field: 'requestedDateTime',
            operator: 'lt',
            value: moment().endOf('day').utc().toDate(),
          },
          {
            field: 'contactId',
            operator: 'gt',
            value: 0,
          },
        ],
      },
    },
    {
      id: 3, name: 'This Week\'s Orders',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'requestedDateTime',
            operator: 'gt',
            value: moment().startOf('week').utc().toDate(),
          },
          {
            field: 'requestedDateTime',
            operator: 'lt',
            value: moment().endOf('week').utc().toDate(),
          },
          {
            field: 'contactId',
            operator: 'gt',
            value: 0,
          },
        ],
      },
    },
    {
      id: 4, name: 'Current Orders',
      default: true,
      filter: {
        logic: "and",
        filters: [
          {
            logic: "and",
            filters: [
              {
                field: 'requestedDateTime',
                operator: 'gt',
                value: moment().startOf('day').utc().toDate(),
              },
              {
                field: 'requestedDateTime',
                operator: 'lt',
                value: moment().endOf('day').utc().toDate(),
              },
              {
                field: 'contactId',
                operator: 'gt',
                value: 0,
              },
            ],
          },
          {
            logic: 'or',
            filters: [
              {
                field: 'OrderStatus/SystemStatusCode',
                operator: 'eq',
                value: 'Placed',
              },
              {
                field: 'OrderStatus/SystemStatusCode',
                operator: 'eq',
                value: 'Preparing',
              },
              {
                field: 'OrderStatus/SystemStatusCode',
                operator: 'eq',
                value: 'Packing',
              },
              {
                field: 'OrderStatus/SystemStatusCode',
                operator: 'eq',
                value: 'OutForDelivery',
              },
            ],
          },
        ],
      },
    },
    {
      id: 5, name: 'Incomplete Orders',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'OrderStatus/SystemStatusCode',
            operator: 'eq',
            value: 'Created',
          },
        ],
      },
    },
    {
      id: 6, name: 'Complete Orders',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'OrderStatus/SystemStatusCode',
            operator: 'eq',
            value: 'Completed',
          },
        ],
      },
    },
  ];

  defaultFilter = this.customFilters.find(filter => filter.default);

  componentDidMount() {
    const { globalFilter } = this.state;

    this.setState({ orders: [] });
    this.props.ordersSelected({});
    this.props.getContacts();
    this.props.getOrderColumns();
    this.props.getStatuses();

    this.setState({ isGridOrderWizardOpen: this.props.wizardOpen });
    this.setupLocations(this.props.getLocations());
    if (this.props.contactsData && this.props.contactsData.length) {
      this.setupContacts(this.props.contactsData);
    }

    if (this.props.orders && this.props.orders.length) {
      this.setupOrderTypes(this.props.orders);
    }

    if (this.props.orders && this.props.orders.length) {
      this.setupCreatedByName(this.props.orders);
    }

    if (this.props.orders && this.props.orders.length) {
      this.setupOrderStatuses(this.props.orders);
    }

    if (!globalFilter) {
      const filterIdFromCache = JSON.parse(localStorage.getItem('filterId')) || 4;
      const foundFilter = this.customFilters.find(filter => filter.id === filterIdFromCache);

      this.setState({
        globalFilter: foundFilter,
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { orders, contactsData, columns } = this.props;

    if (orders !== prevProps.orders) {
      if (orders && (prevProps.orders !== orders || (orders.length !== 0 && this.state.orders.length === 0))) {
        this.setState({ orders: this.setSelectedOrders(this.state.selectedIds) });
      }
    }

    if (prevProps.contactsData !== contactsData) {
      this.setupContacts(contactsData);
    }
    if (prevProps.columns !== columns) {
      this.setState({ columns });
    }
    if (prevState.includeDelivery !== this.state.includeDelivery || prevState.includePickup !== this.state.includePickup) {
      this.resetOrderTypeFilter();
    }
  }

  setupContacts = (contacts: IContact[]) => {
    const dropDownOptions: any[] = [];
    const contactsMap = {};

    if (contacts) {
      contacts.forEach(s => {
        dropDownOptions.push({
          text: s.name,
          id: s.id,
        });

        contactsMap[s.id] = s.name;
      });
    }

    this.contactsFilterCell = dropdownFilterCell(dropDownOptions, 'Contacts');
    this.setState({ contactsMap, contacts }); //convert from array to dict
  };

  setupLocations = (locations: any[]) => {
    const dropDownOptions = locations.map(s => (
      {
        text: s.name,
        id: s.id,
      }));
    const locationsFilterCell = dropdownFilterCell(dropDownOptions, 'Locations');

    this.setState({ locationsFilterCell });
  };

  setupOrderTypes = (orders: IOrder[]) => {
    const options = [{ id: "Delivery", text: "Delivery" }, { id: "Pickup", text: "Pickup" }];

    const orderTypeFilterCell = dropdownFilterCell(options, 'Type');

    this.setState({ orderTypeFilterCell });
  };

  setupCreatedByName = (orders: IOrder[]) => {
    const orderReps = orders.map(s => (
      {
        text: s.createdByName,
        id: s.createdByName,
      }));

    const dropDownOptions = orderReps.filter((v, i) => i === orderReps.findIndex(a => a.text === v.text && a.id === v.id)).sort((a, b) => a.text.localeCompare(b.text));

    const createdByNameFilterCell = dropdownFilterCell(dropDownOptions, 'Order Rep');

    this.setState({ createdByNameFilterCell });
  };

  setupOrderStatuses = (orders: IOrder[]) => {

    const pickupStatuses = this.props.statuses.find((s: any) => s.type == "PickupOrder").statuses.map((s: any) => s.name);
    const deliveryStatuses = this.props.statuses.find((s: any) => s.type == "DeliveryOrder").statuses.map((s: any) => s.name);

    const statuses = pickupStatuses.concat(deliveryStatuses).filter((v: any, i: number, a: any) => a.indexOf(v) === i).map((s: any) => ({ id: s, text: s }));

    const orderStatusesFilterCell = dropdownFilterCell(statuses, 'Order Status');

    this.setState({ orderStatusesFilterCell });
  };

  setSelectedOrders = (selectedIds = {}) => {
    const { orders } = this.props;

    if (orders) {
      if (orders.length === 0) {
        return orders;//avoid creating a new array each time
      }

      return orders.map(order => ({
        ...order,
        selected: selectedIds[order.id] === true,
      }));
    }

    return null;
  }

  saveOrders = (orders: IOrderExtended[]) => {
    if (this.state.isQuickAdding) {
      orders = orders.filter(c => c.isEditable);
    }
    this.props.saveOrders(orders)
      .then(this.cancelEditMode)
      .catch(console.warn); //TODO: handle errors better. Mockups?
  }

  enterEditMode = () => {
    if (!this.state.isQuickAdding) {
      this.setState({ isEditable: true });
    }
  }

  cancelEditMode = () => {
    if (this.state.isQuickAdding) {
      const orders = this.state.orders.filter((c: IOrderExtended) => !c.isEditable);

      this.setState({ orders });
    }
    this.setState({ isEditable: false, isQuickAdding: false });
  }

  onQuickAdd = () => {
    const orders = [
      { isEditable: true, isQuickAdd: true } as IOrderExtended,
      ...this.state.orders,
    ];

    this.setState({ orders, isQuickAdding: true });
  }

  openOrderWizard = () => {
    const { currentLocation } = this.props;

    if (currentLocation && currentLocation.id) {
      this.setState({ isGridOrderWizardOpen: true });
    } else {
      this.okDialog.current.open('Please select a location before starting an order')
        .then(() => this.setState({ isGridOrderWizardOpen: true }));
    }
  };

  closeOrderWizard = () => {
    this.setState({ isGridOrderWizardOpen: false });
  }

  onFilterClick = () => {
    this.setState({ isFilterable: !this.state.isFilterable });
  }

  onOrderSelected = ({ id, selected, allSelected }: any) => {
    const { orders, selectedIds } = this.state;
    let newOrders;

    if (allSelected !== undefined) {
      newOrders = orders.map(c => {
        c.selected = allSelected;
        selectedIds[c.id] = allSelected;

        return c;
      });
    } else {
      newOrders = orders.map(c => {
        if (c.id === id) {
          c.selected = selected;
        }
        selectedIds[c.id] = c.selected;

        return c;
      });
    }

    const selectedOrders = newOrders.filter(order => order.selected);

    this.setState({ orders: newOrders, selectedIds });
    this.props.ordersSelected(selectedOrders);
  };

  toggleDelivery = () => {
    const { includeDelivery, includePickup } = this.state;

    if (includePickup || !includeDelivery) {
      this.setState({ ...this.state, includeDelivery: !includeDelivery });
    }
  }

  togglePickup = () => {
    const { includePickup, includeDelivery } = this.state;

    if (!includePickup || includeDelivery) {
      this.setState({ ...this.state, includePickup: !includePickup });
    }
  }

  resetOrderTypeFilter = () => {
    const { filterId } = this.state;

    let globalFilter = this.state.globalFilter;

    const { includeDelivery, includePickup } = this.state;

    if (globalFilter && globalFilter.filter && globalFilter.filter.filters && globalFilter.filter.filters.length > 0) {
      globalFilter.filter.filters = globalFilter.filter.filters.filter((f: any) => f.field !== 'type');
    }
    else {
      globalFilter = this.customFilters.find(filter => filter.id === filterId);
    }

    let filter = this.customFilters.find(filter => filter.id === filterId);

    if (filter) {
      filter.filters = [];
    }
    else {
      filter = {
        id: 1,
        name: 'All Orders',
        filters: [],
        filter: undefined,
      }
    }
    if (includeDelivery && !includePickup) {
      filter.filters.push(
        {
          field: 'type',
          operator: 'eq',
          value: 'Delivery',
        },
      );
    } else if (!includeDelivery && includePickup) {
      filter.filters.push(
        {
          field: 'type',
          operator: 'eq',
          value: 'Pickup',
        },
      );
    }


    this.setState({
      ...this.state,
      filterId,
      globalFilter: {
        ...globalFilter,
        name: globalFilter ? globalFilter.name : 'All Orders',
        id: filterId,
        filter: { ...filter },
      },
    });
  }

  deliveryButtons = () => {
    const { includeDelivery, includePickup } = this.state;

    return (
      <div>
        <HighlightButton
          onClick={this.toggleDelivery}
          aria-label="Delivery"
          variant="left"
          pressed={includeDelivery}
        >
          <Delivery />
        </HighlightButton>
        <HighlightButton
          onClick={this.togglePickup}
          aria-label="Pickup"
          variant="right"
          pressed={includePickup}
        >
          <Pickup />
        </HighlightButton>
      </div>
    );
  }

  applyCustomFilter = (filterId: number) => {
    const foundFilter = this.customFilters.find(filter => filter.id === filterId);

    this.setState({
      globalFilter: foundFilter,
      includeDelivery: true,
      includePickup: true,
    });

    this.setState({
      filterId,
    }, () => {
      localStorage.setItem('filterId', JSON.stringify(this.state.filterId));
    });
  }

  getToolbar = () => (
    <GridToolBar
      additionalButtons={this.deliveryButtons()}
      isFilterToggled={this.state.isFilterable}
      isEditToggled={this.state.isEditable}
      isGridToggled={this.state.isGridToggled}
      onFilterClick={this.onFilterClick}
      onEditClick={this.enterEditMode}
      onQuickAdd={this.onQuickAdd}
      onAddClick={this.openOrderWizard}
      onGridEditClick={this.onGridEditClick}
      onColumnsSubmit={this.saveGridColumns}
      onCloseMenu={this.onGridEditClose}
      columns={this.state.columns}
      gridContext="Orders"
      filterId={this.state.filterId}
      customFilters={this.customFilters}
      onApplyCustomFilter={this.applyCustomFilter}
      activeCustomFilter={this.state.globalFilter}
    />
  )

  saveGridColumns = (columns: any) => {
    this.props.saveOrderColumns(columns);
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  reorderColumns = (event: any) => {
    const { columns: reOrderColumns } = event;
    const reorderedColumns = reOrderColumns.sort((a: any, b: any) => a.orderIndex - b.orderIndex).map((column: any) => column.field);

    this.props.reorderOrderColumns(reorderedColumns);
  }

  render() {
    const {
      isEditable,
      isFilterable,
      isQuickAdding,
      columns,
      globalFilter,
      sort,
      orders,
      includePickup,
      includeDelivery,
      isGridOrderWizardOpen,
    } = this.state;
    const {
      isLoading,
      getStateODataSettings,
    } = this.props;
    const pageSize = getStateODataSettings();

    return (
      <>
        <ODataGrid
          getData={this.props.getOrders}
          items={orders}
          isLoading={isLoading}
          isQuickAdding={isQuickAdding}
          selectionChanged={this.onOrderSelected}
          allItemsSelected={({ selected }) => this.onOrderSelected({ allSelected: selected })}
          isSortable={!isEditable}
          filterable={!isEditable && isFilterable}
          isEditable={isEditable}
          pageSize={pageSize}
          onSave={this.saveOrders}
          onCancel={this.cancelEditMode}
          toolBar={this.getToolbar()}
          onColumnReorder={this.reorderColumns}
          filter={(globalFilter && globalFilter.filter)}
          sort={sort}
        >
          {columns.map((column: any) => this.getColumn(column))}

        </ODataGrid>
        {isGridOrderWizardOpen &&
          <OrderWizardAny
            orderType={includePickup && !includeDelivery ? 'Pickup' : 'Delivery'}
            onClose={this.closeOrderWizard}
          />
        }
        <SimpleDialog innerRef={this.okDialog} onlyOkayButton={true} />
      </>
    );
  }

  onDropdownChange = (props: any) => (newValue: any) => {
    props.onChange({ dataItem: props.dataItem, field: newValue.name, value: newValue.value });
  }

  getColumn(column: any) {
    const {
      isEditable,
      isQuickAdding,
      orders,
      locationsFilterCell,
      orderTypeFilterCell,
      createdByNameFilterCell,
      orderStatusesFilterCell,
    } = this.state;

    if (!column.isVisible) {
      return null;
    }

    switch (column.field) {
      case 'selected':
        return SelectedColumn(orders, !isEditable && !isQuickAdding);
      case 'id':
        return <GridColumn filter="numeric" key={column.field} field={column.field} title="Order #" cell={buildGridCellLink(this.linkOptions) as any} />
      case 'type':
        return <GridColumn
          key={column.field}
          field={column.field}
          title={column.title}
          filterCell={orderTypeFilterCell}
        />;
      case 'orderStatus':
        return <GridColumn
          key={column.field}
          field="orderStatus/name"
          title={column.title}
          filterCell={orderStatusesFilterCell}
          cell={props => (
            <td>{props.dataItem.orderStatus}</td>
          )}
        />;
      case 'total':
        return <GridColumn filter="numeric" key={column.field} field={column.field} title="Total" cell={props => (
          <td>${props.dataItem.total && props.dataItem.total.toFixed(2)}</td>
        )} />;

      case 'requestedDateTime':
        return <GridColumn filter="date" key={column.field} field={column.field} title="Date Needed" cell={props => (
          <td>{(props.dataItem.requestedDateTime && moment(props.dataItem.requestedDateTime).format('l LT')) || 'ASAP'}</td>
        )} />;
      case 'placedDateTime':
        return <GridColumn filter="date" key={column.field} field={column.field} title="Placed Date Time" cell={props => (
          <td>{props.dataItem.placedDateTime ? moment(props.dataItem.placedDateTime).format('l LT') : ''}</td>
        )} />;
      case 'address':
        return <GridColumn key={column.field} field={column.field} title="Address" cell={props => (
          <td>{props.dataItem.orderDelivery && props.dataItem.orderDelivery.address1}</td>
        )} />;
      case 'createdByName':
        return <GridColumn filter="text" key={column.field} field={column.field} title="Order Rep" filterCell={createdByNameFilterCell} cell={props => (
          <td>{props.dataItem.createdByName}</td>
        )} />;
      case 'location':
        return <GridColumn key={column.field} field={column.field} title="Location" filterCell={locationsFilterCell} cell={props => (
          <td>{props.dataItem.location && props.dataItem.location.name}</td>
        )} />;
      case 'customerPhone':
        return <GridColumn key={column.field} field={column.field} title="Phone #" />;
      case 'customerName':
        return <GridColumn key={column.field} field={column.field} title="Client" />;
      default:
        return <GridColumn key={column.field} field={column.field} title={column.title} />;
    }
  }
}

const mapStateToProps = (state: any) => {
  const {
    api: {
      currentLocation,
    },
    contact: {
      contacts: contactsData,
    },
    storeReducer: {
      orders: {
        list,
        isLoading,
      },
    },
    settings: {
      statuses,
      orders: {
        columns,
      },
    },
  } = state;

  return {
    orders: list, // `list` is a lighter-weight collection of `data`
    isLoading,
    contactsData,
    columns,
    currentLocation,
    statuses,
  };
};

const mapDispatchToProps = {
  getContacts,
  saveOrders,
  getOrders,
  ordersSelected,
  getOrderColumns,
  saveOrderColumns,
  getLocations,
  reorderOrderColumns,
  getStatuses,
  getStateODataSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderGrid);

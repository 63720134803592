import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const styles = theme => ({
  avatar: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.common.bluegrey,
    fontSize: 42,
    fontWeight: '600',
    minWidth: '119px',
    minHeight: '93px',
    color: theme.palette.grey['50'],
  },
});

class Avatar extends Component {
  generateRandomColor = index => {
    const { cardColors } = this.props;

    return cardColors.length ? cardColors[index % cardColors.length] : '';
  }

  Avatar = name => {

    if (!name || name === '') {
      return '';
    }

    const initials = this.getInitials(name);

    if (initials[0]) {

      let initialValue = initials[0].charCodeAt(0);

      if (initials[1]) {
        initialValue = initialValue + initials[1].charCodeAt(0);
      }

      return (
        <div
          className={this.props.className || this.props.classes.avatar}
          style={{ background: this.props.color ? this.props.color : this.generateRandomColor(initialValue) }}
        >
          {initials}
        </div>
      );
    }

    return '';
  };

  getInitials = name => {
    if (name) {
      let names = name.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      return initials;
    }

    return '';
  };

  render() {
    const {
      name,
    } = this.props;

    return (
      <>
        {this.Avatar(name)}
        {this.props.children}
      </>
    );

  }
}

const mapStateToProps = (state, ownProps) => ({
  cardColors: state.settings.allAreaSettings.ACCOUNT_COLOR_SCHEME
    ? state.settings.allAreaSettings.ACCOUNT_COLOR_SCHEME.value
    : [],
});

export default connect(mapStateToProps)(withStyles(styles)(Avatar));

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  EventAvailable as Confirmed,
  DateRange as Unconfirmed,
} from '@material-ui/icons';
import { GridColumn } from '@progress/kendo-react-grid';
import { getBookingsIfNeeded, getBookingColumns, saveBookingColumns, reorderBookingColumns } from 'actions/booking';
import { getStoreMenu } from 'actions/storemenu';
import { bookingsSelected, confirmedBookingsToggled } from 'actions/api';
import { getSalesRepsIfNeeded } from 'actions/contact';
import ODataGrid from 'Components/Grid/ODataGrid';
import SelectedColumn from 'Components/Grid/SelectedColumn';
import GridToolBar from 'Components/Grid/GridToolBar';
import dropdownFilterCell from 'Components/Grid/dropdownFilterCell';
import HighlightButton from 'Components/Buttons/HighlightButton';
import { buildGridCellLink } from 'Components/buildGridCellLink';
import { defaultBookingsGrid } from 'constants/apiParamDefaults';
import BookingWizard from './BookingWizard/BookingWizard';
import { MenuType } from 'models/MenuType';
import { getLocations } from 'actions/location';
import { getStatuses } from 'actions/settings';

const dollarColumns = ['balance', 'cost', 'discount', 'paid', 'profit', 'serviceCharged', 'subTotal', 'taxCharged', 'total'];

class BookingGrid extends Component {
  state = {
    selectedBookingIds: {},
    isFilterable: false,
    isGridToggled: false,
    bookings: [],
    isLoading: true,
    salesRepsMap: {},
    salesRepsData: [],
    statusMap: {},
    statuses: [],
    columns: this.props.columns,
    wizardType: null,
    sort: defaultBookingsGrid.orderBy,
    activeFilter: defaultBookingsGrid.filter,
    isConfirmedToggled: true,
    globalFilter: null,
  };

  customFilters = () => [
    {
      id: 2, name: 'Today\'s Bookings',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'dateBooked',
            operator: 'gt',
            value: moment().startOf('day').utc().toDate(),
          },
          {
            field: 'dateBooked',
            operator: 'lt',
            value: moment().endOf('day').utc().toDate(),
          },
        ],
      },
    },
    {
      id: 3, name: 'This Week\'s Bookings',
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'dateBooked',
            operator: 'gt',
            value: moment().startOf('week').utc().toDate(),
          },
          {
            field: 'dateBooked',
            operator: 'lt',
            value: moment().endOf('week').utc().toDate(),
          },
        ],
      },
    },
    {
      id: 4, name: 'Current Bookings',
      default: true,
      filter: {
        logic: "and",
        filters: [
          {
            logic: "and",
            filters: [
              {
                field: 'dateBooked',
                operator: 'gt',
                value: moment().startOf('day').utc().toDate(),
              },
              {
                field: 'dateBooked',
                operator: 'lt',
                value: moment().endOf('day').utc().toDate(),
              },
            ],
          },
        ],
      },
    }]

  linkToBooking = buildGridCellLink({
    url: booking => `/bookings/${booking.id}`,
    text: booking => booking.name,
  });

  componentDidMount() {
    this.props.bookingsSelected({});
    this.props.getBookingColumns();
    this.props.getStoreMenu(MenuType.Booking);
    this.props.getStatuses();
    if (this.props.salesRepsData.length) {
      this.setupSalesReps(this.props.salesRepsData);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.bookings !== this.props.bookings || (this.props.bookings.length !== 0 && this.state.bookings.length === 0)) {
      this.setState({ bookings: this.setSelectedBookings(this.state.selectedBookingIds), isLoading: false });
    }
    if (prevProps.columns !== this.props.columns) {
      this.setState({ columns: this.props.columns });
    }
    if (prevProps.salesRepsData !== this.props.salesRepsData) {
      this.setupSalesReps(this.props.salesRepsData);
    }
    if (prevState.isConfirmedToggled !== this.state.isConfirmedToggled) {
      this.updateBookingsByConfirmedStatus();
    }
    if (prevProps.statuses !== this.props.statuses) {
      const bookingStatuses = this.props.statuses.find(s => s.type === 'Booking');

      this.setupStatuses(bookingStatuses.statuses);
    }

  }

  setSelectedBookings = (selectedBookingIds = {}) => {
    const { bookings } = this.props;

    if (bookings.length === 0) {
      return bookings; //avoid creating a new array each time
    }

    return bookings.map(booking => ({
      ...booking,
      selected: selectedBookingIds[booking.id] === true,
    }));
  }

  setupSalesReps = salesReps => {
    const dropDownOptions = [];
    const salesRepsMap = {};

    if (salesReps) {
      salesReps.forEach(s => {
        dropDownOptions.push({
          text: s.name,
          id: s.id,
        });
        salesRepsMap[s.id] = s.name;
      });
    }

    this.salesRepFilterCell = dropdownFilterCell(dropDownOptions, 'Sales Rep');
    this.setState({ salesRepsMap, salesReps });
  };

  setupLocations = locations => {
    const dropDownOptions = locations.map(s => (
      {
        text: s.name,
        id: s.id,
      }));
    const locationsFilterCell = dropdownFilterCell(dropDownOptions, 'Locations');

    this.setState({ locationsFilterCell });
  };

  setupStatuses = statuses => {
    const dropDownOptions = [];
    const statusMap = {};

    if (statuses) {
      statuses.forEach(s => {
        dropDownOptions.push({
          text: s.name,
          id: s.id,
        });
        statusMap[s.id] = s.name;
      });
    }

    this.statusFilterCell = dropdownFilterCell(dropDownOptions, 'Status');
    this.setState({ statusMap, statuses });
  };

  onFilterClick = () => {
    this.setState({ isFilterable: !this.state.isFilterable });
  }

  onBookingSelected = ({ id, isSelected, isAll }) => {
    const { bookings, selectedBookingIds } = this.state;
    let newBookings;

    if (isAll !== undefined) {
      newBookings = bookings.map(booking => {
        booking.selected = isAll;
        selectedBookingIds[booking.id] = isAll;

        return booking;
      });
    } else {
      newBookings = bookings.map(booking => {
        if (booking.id === id) {
          booking.selected = isSelected;
        }
        selectedBookingIds[booking.id] = booking.selected;

        return booking;
      });
    }
    this.setState({ bookings: newBookings, selectedBookingIds });
    this.props.bookingsSelected(selectedBookingIds);
  };

  onGridEditClick = () => {
    this.setState({ isGridToggled: !this.state.isGridToggled });
  }

  onConfirmedClick = () => {
    this.setState({ isConfirmedToggled: true });
  }

  onUnconfirmedClick = () => {
    this.setState({ isConfirmedToggled: false });
  }

  updateBookingsByConfirmedStatus = () => {
    const { isConfirmedToggled } = this.state;
    const { filterId } = this.state;

    let globalFilter = this.state.globalFilter;

    if (globalFilter && globalFilter.filter && globalFilter.filter.filters && globalFilter.filter.filters.length > 0) {
      globalFilter.filter.filters = globalFilter.filter.filters.filter(f => f.field !== 'confirmed');
    }
    else {
      globalFilter = this.customFilters().find(filter => filter.id === filterId);
    }

    let filter = this.customFilters().find(filter => filter.id === filterId);

    if (!filter) {
      filter = {
        id: 1,
        name: 'All Bookings',
        filters: [],
        filter: undefined,
      };
    }
    else {
      filter.filter = undefined;
    }

    if (!filter.filters) {
      filter.filters = [];
    }

    if (isConfirmedToggled) {
      filter.filters.push(
        {
          field: 'confirmed',
          operator: 'eq',
          value: true,
        },
      );
    } else {
      filter.filters.push(
        {
          field: 'confirmed',
          operator: 'eq',
          value: false,
        },
      );
    }

    this.setState({
      ...this.state,
      filterId,
      globalFilter: {
        ...globalFilter,
        name: globalFilter ? globalFilter.name : 'All Bookings',
        id: filterId,
        filter: { ...filter },
      },
    });
  }

  saveGridColumns = columns => {
    this.props.saveBookingColumns(columns);
  }

  reorderColumns = event => {
    const { columns: reOrderColumns } = event;
    // If the data field does not match the column field name, we have to fix it here:
    const fieldNames = {
      'account.name': 'account',
      'account.phone': 'phone',
      'contact.name': 'contact',
      'salesRepId': 'salesRep',
    };
    const reorderedColumns = reOrderColumns.sort((a, b) => a.orderIndex - b.orderIndex).map(column => fieldNames[column.field] || column.field);

    this.props.reorderBookingColumns(reorderedColumns);
  }

  onGridEditClose = () => {
    this.setState({ isGridToggled: false });
  }

  openBookingWizard = wizardType => {
    this.setState({ wizardType, menuTypeOpenedBy: null });
  }

  closeBookingWizard = () => {
    this.setState({ wizardType: null });
  }

  confirmedButtons = () => {
    const { isConfirmedToggled } = this.state;

    return (
      <div>
        <HighlightButton
          onClick={this.onUnconfirmedClick}
          aria-label="Unconfirmed"
          variant="left"
          pressed={!isConfirmedToggled}
        >
          <Unconfirmed />
        </HighlightButton>
        <HighlightButton
          onClick={this.onConfirmedClick}
          aria-label="Confirmed"
          variant="right"
          pressed={isConfirmedToggled}
        >
          <Confirmed />
        </HighlightButton>
      </div>
    );
  }

  applyCustomFilter = filterId => {
    const foundFilter = this.customFilters().find(filter => filter.id === filterId);

    this.setState({
      globalFilter: foundFilter,
    });

    this.setState({
      filterId,
    }, () => {
      localStorage.setItem('filterId', JSON.stringify(this.state.filterId));
    });
  }

  getToolbar = () => (
    <GridToolBar
      isFilterToggled={this.state.isFilterable}
      isGridToggled={this.state.isGridToggled}
      onFilterClick={this.onFilterClick}
      additionalButtons={this.confirmedButtons()}
      onAddClick={() => this.openBookingWizard('Booking')}
      onGridEditClick={this.onGridEditClick}
      onColumnsSubmit={this.saveGridColumns}
      onCloseMenu={this.onGridEditClose}
      columns={this.state.columns}
      gridContext="Bookings"
      customFilters={this.customFilters()}
      onApplyCustomFilter={this.applyCustomFilter}
      activeCustomFilter={this.state.globalFilter}
    />
  )

  render() {
    const {
      bookings,
      isFilterable,
      columns,
      isLoading,
      wizardType,
      sort,
      globalFilter,
    } = this.state;

    const {
      total,
    } = this.props;

    return (
      columns.length > 0 &&
      <>
        <ODataGrid
          getData={this.props.getBookingsIfNeeded}
          items={bookings}
          total={total}
          isLoading={isLoading}
          selectionChanged={this.onBookingSelected}
          isSortable={true}
          filterable={isFilterable}
          toolBar={this.getToolbar()}
          onColumnReorder={this.reorderColumns}
          filter={(globalFilter && globalFilter.filter)}
          sort={sort}
        >
          {columns.map(column => this.getColumn(column))}
        </ODataGrid>
        {Boolean(wizardType) && <BookingWizard onClose={this.closeBookingWizard} />}
      </>
    );
  }

  getColumn(column) {
    const {
      bookings,
    } = this.state;

    if (column.isVisible) {
      switch (column.field) {
        case 'selected':
          return SelectedColumn(bookings);
        case 'name':
          return <GridColumn key={column.field} field={column.field} title="Booking Name" cell={this.linkToBooking} />;
        case 'phone':
          return <GridColumn key={column.field} field="account.phone" title="Account Phone" />;
        case 'status':
          return <GridColumn
            key={column.field}
            title={column.title}
            filterCell={this.statusFilterCell}
            field="statusId"
            cell={props => (
              <td>{props.dataItem.bookingStatus ? props.dataItem.bookingStatus.name : 'Proposed'}</td>
            )}
          />;
        case 'contact':
          return <GridColumn key={column.field} field="contact.name" title={column.title} />;
        case 'account':
          return <GridColumn key="account" field="account.name" title="Account" />;
        // case 'salesRep': TODO
        //   return (
        //     <GridColumn
        //       key={column.field}
        //       field="salesRepId" // set to salesRepId for filtering to work
        //       title={column.title}
        //       filterCell={this.salesRepFilterCell}
        //       cell={props => <td>{salesRepsMap[props.dataItem.salesRepId]}</td>} />);
        case 'dateBooked':
          return <GridColumn
            key={column.field}
            field={column.field}
            title={column.title}
            cell={props => (
              <td>{moment(props.dataItem.dateBooked).format('l')}</td>
            )}
          />;
        case 'startDateTime':
          return <GridColumn
            key={column.field}
            field={column.field}
            title="Start Date/Time"
            editable={false}
            cell={props => (
              <td>{moment(props.dataItem[column.field]).format('l LT')}</td>
            )}
          />;
        case 'endDateTime':
          return <GridColumn
            key={column.field}
            field={column.field}
            title="End Date/Time"
            editable={false}
            cell={props => (
              <td>{moment(props.dataItem[column.field]).format('l LT')}</td>
            )}
          />;
        case 'events':
          return <GridColumn
            key={column.field}
            field={column.field}
            title="# of Events"
            sortable={false}
            editable={false}
            cell={props => (
              <td>{props.dataItem.events && props.dataItem.events.length}</td>
            )}
          />;
        // case 'location': TODO
        //   return (
        //     <GridColumn
        //       key={column.field}
        //       field="locationId" // set to locationId for filtering to work
        //       title={column.title}
        //       filterCell={locationsFilterCell}
        //       cell={props => <td>{props.dataItem.location.name}</td>} />);
        default:
          if (dollarColumns.indexOf(column.field) !== -1) {
            return <GridColumn key={column.field} field={column.field} title={column.title} format="{0:C}" filter="numeric" />;
          }

          return <GridColumn key={column.field} field={column.field} title={column.title} />;
      }
    }
  }
}

const mapStateToProps = state => {
  const {
    api: {
      bookings: {
        data,
        total,
        selectedBookingIds,
        isConfirmedToggled,
      },
      salesReps: {
        salesRepsData,
      },
    },
    settings: {
      statuses,
      bookings: {
        columns,
      },
    },
  } = state;

  return {
    statuses,
    bookings: data,
    columns,
    total,
    salesRepsData,
    selectedBookingIds,
    isConfirmedToggled,
  };
};

const mapDispatchToProps = {
  getBookingsIfNeeded,
  bookingsSelected,
  getBookingColumns,
  saveBookingColumns,
  getSalesRepsIfNeeded,
  confirmedBookingsToggled,
  getLocations,
  reorderBookingColumns,
  getStoreMenu,
  getStatuses,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingGrid);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Link, withRouter, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Avatar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core';
import {
  BrightnessHigh as PersonIcon,
  AddAlert as NotificationIcon,
  ArrowDropDown,
  Help as HelpIcon,
} from '@material-ui/icons';
import MessagesIcon from 'shared/icons/message.js';
import AdminNav from './admin/admin_nav';
import SideNav from './SideNav';
import Cover from 'logo.svg';
import HealthIcon from 'shared/icons/monitor-heart';
import SearchBox from 'Components/Search/SearchBox';
import { resetAndReseed } from 'actions/admin';
import SimpleDialog from 'Components/SimpleDialog';
import { logout } from 'auth/userManager';
import { getLocations } from 'actions/location';
import HealthCheckModal from './HealthChecksModal';

const styles = theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    paddingTop: '64px',
    overflow: 'hidden',
    position: 'relative',
    width: '100vw',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.paper,
    paddingRight: '0px !important',
  },
  appBarAttention: {
    display: 'flex',
    justifyContent: 'center',
    padding: '6px 32px 6px 16px',
    backgroundColor: '#f96a6a',
  },
  appBarAttentionMessage: {
    fontSize: 18,
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  navBar: {
    width: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
  },
  toolBar: { //doesn't need display: 'flex' because the <Toolbar> already has it
    justifyContent: 'space-between',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  },
  logo: {
    width: 'auto',
    height: 55,
    maxWidth: 200,
    maxHeight: 60,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main, //used to be 386ca7, but it's not in the pallete
    fontSize: 14,
  },
  name: {
    color: theme.palette.grey[300],
  },
  nameAndLocation: {
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    marginRight: 4,
  },
  dividers: {
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    borderRight: `1px solid ${theme.palette.grey[100]}`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  icons: {
    color: theme.palette.grey[100],
  },
  currentLocation: {
    color: theme.palette.secondary.dark,
  },
  navLink: {
    margin: 0,
    height: 60,
    padding: 3,
    paddingLeft: 6,
    borderBottom: 'solid 3px transparent',
    '&.active': {
      borderBottom: 'solid 3px #2189F8',
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: theme.palette.grey[50],
    },

  },
  healthCheckBox: {
    marginRight: '5px',
    paddingBottom: '2px',
  },
  healthCheckSuccess: {
    fill: 'green',
  },
  healthCheckFail: {
    fill: 'red',
  },
});

class Navs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorLocations: null,
      isBookingWizardOpen: false,
      isOrderWizardOpen: false,
      showMoreButton: false,
      locations: [],
    };

    this.clickCount = 0;
    this.confirmResetDialog = React.createRef();
  }

  componentDidMount() {
    const locations = this.props.getLocations();

    if (locations) {
      // when internal users change between organizations, current location value stays in the stays
      // the logic bellow validates that current location is in the list of authorized user and
      // if it is not, select first one
      const setCurrentLocation = !this.props.currentLocation || !locations.some(location => location.id === this.props.currentLocation.id);

      if (setCurrentLocation) {
        this.props.setCurrentLocation(locations[0]);
      }
    }
  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleProfileMenuClose = () => {
    this.setState({ anchorEl: null });
  }

  handleLocationsOpen = event => {
    const { canSwitchLocations } = this.props;

    if (canSwitchLocations) {
      this.setState({ anchorLocations: event.currentTarget, loadingLocations: true });
      this.setState({ locations: this.props.getLocations(), loadingLocations: false });
    }
  }

  handleLocationMenuClose = () => {
    this.setState({ anchorLocations: null, locations: [] });
  }

  selectNewLocation = location => () => {
    this.handleLocationMenuClose();
    this.props.setCurrentLocation(location);
  }

  myAccount = event => {
    window.location.href = `${window.env.REACT_APP_GATEWAY}/Account/Manage/ProfileInformation`;
  }

  logout = event => {
    event.preventDefault();
    this.props.logout();
  }

  settingsClicked = () => {
    this.clickCount++;
    if (this.clickCount >= 5) {
      this.confirmResetDialog.current.open()
        .then(this.props.resetAndReseed)
        .finally(() => {
          this.clickCount = 0;
        });
    }
  }

  handleHealthClose = () => {
    this.setState({ openHealthCheckModal: false });
  }

  performHealthcheck = () => {
    this.setState({ openHealthCheckModal: true });
  }

  render() {
    const {
      classes,
      initals,
      fullName,
      canSwitchLocations,
      currentLocation = {},
      hasAccessToAdmin,
      onlineOrderEnabledSetting,
      logo,
    } = this.props;
    const {
      anchorEl,
      anchorLocations,
      openHealthCheckModal,
      locations,
      loadingLocations,
    } = this.state;

    const onlineOrderDisabled = onlineOrderEnabledSetting
      ? onlineOrderEnabledSetting.value !== onlineOrderEnabledSetting.options[0]
      : false;

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          classes={{ root: classes.appBar }}
        >
          <Toolbar disableGutters={true} className={classes.toolBar}>
            <div>
              <Link className={classes.navLink} to='/home'>
                <img
                  className={classes.logo}
                  alt="cover logo"
                  src={logo || Cover}
                />
              </Link>
            </div>
            <SearchBox />
            <div>
              <a target="_blank" rel="noopener noreferrer" href="http://support.usegraze.com/helpmenu/content/homelite.htm">
                <IconButton>
                  <HelpIcon />
                </IconButton>
              </a>
              <IconButton>
                <NotificationIcon className={classes.icons} />
              </IconButton>
              <Button onClick={this.handleProfileMenuOpen} >
                <Avatar
                  className={classes.avatar}>
                  {initals}
                </Avatar>
              </Button>
              <div
                className={classes.nameAndLocation}
                onClick={this.handleLocationsOpen}
              >
                <div className={classes.name}>
                  {fullName}
                  {canSwitchLocations && <ArrowDropDown className={classes.icons} />}
                </div>
                <div className={classes.currentLocation}>{currentLocation && currentLocation.name}</div>
              </div>
              <div className={classes.dividers}>
                <IconButton
                  onClick={this.performHealthcheck}>
                  <HealthIcon className={classes.icons} />
                </IconButton>
              </div>
              <div className={classes.dividers}>
                <IconButton
                  onClick={this.settingsClicked}>
                  <PersonIcon className={classes.icons} />
                </IconButton>
              </div>
              <IconButton>
                <MessagesIcon className={classes.icons} />
              </IconButton>

            </div>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleProfileMenuClose}
            >
              <MenuItem onClick={this.myAccount}>My account</MenuItem>
              <MenuItem onClick={this.logout}>Logout</MenuItem>
            </Menu>
            <Menu
              id="allLocations"
              anchorEl={anchorLocations}
              open={Boolean(anchorLocations)}
              onClose={this.handleLocationMenuClose}
            >
              {loadingLocations && <MenuItem>Loading</MenuItem>}
              {locations.map(location =>
                <MenuItem
                  selected={currentLocation && location.id === currentLocation.id}
                  key={location.id}
                  onClick={this.selectNewLocation(location)}>
                  {location.name}
                </MenuItem>
              )}
            </Menu>
            {openHealthCheckModal && <HealthCheckModal onModalClosed={this.handleHealthClose} />}
          </Toolbar>
          {onlineOrderDisabled &&
            <Paper elevation={2} className={classes.appBarAttention}>
              <div className={classes.appBarAttentionMessage}>Online orders are manually disabled.</div>
            </Paper>
          }
        </AppBar>

        <Switch>
          <Route path="/admin" component={AdminNav} />
          <Route path="/" component={() => <SideNav hasAccessToAdmin={hasAccessToAdmin} />} />
        </Switch>

        <main className={classes.content}>
          {this.props.children}
        </main>
        <SimpleDialog
          message="Are you sure you want to reset the database?"
          innerRef={this.confirmResetDialog}
        />
      </div>
    );
  }
}

Navs.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  const {
    oidc: { user },
    settings: { allAreaSettings },
  } = state;
  let initals = '';
  let fullName = user.profile.given_name;

  if (fullName) {
    initals += fullName[0];
  }

  if (user.profile.family_name) {
    initals += user.profile.family_name[0];
    if (fullName) {
      fullName = `${fullName} ${user.profile.family_name}`;
    } else {
      fullName = user.profile.family_name;
    }
  }
  if (!initals) {
    initals = 'na'; //not applicable
    fullName = 'not available';
  }

  return {
    user,
    onlineOrderEnabledSetting: allAreaSettings ? allAreaSettings.GRAZE_ONLINE_ORDERS_ENABLED : undefined,
    logo: allAreaSettings && allAreaSettings.COVER_COMPANY_LOGO ? allAreaSettings.COVER_COMPANY_LOGO.value : undefined,
    idToken: user.profile.id_token,
    initals,
    fullName,
  };
};

const mapDispatchToProps = {
  logout,
  resetAndReseed,
  getLocations,
};

export const NavsWithoutContext = withRouter(withStyles(styles)(Navs));

export default connect(mapStateToProps, mapDispatchToProps)(NavsWithoutContext);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'Components/Modal';
import { reportPdfUrl } from 'actions/reports';
import PrimaryButton from 'Components/Buttons/PrimaryButton';
import { withStyles } from '@material-ui/core/styles';
import { uploadFile } from 'actions/file';
import { CircularProgress }  from '@material-ui/core';
import moment from 'moment';

//these dimensions seem to work well accross most screen sizes. Breaks down around 300px height; which we're not supporting.
const dimensions = {
  width: '93%',
  height: '87%',
};

const styles = {
  iFrameStyle: {
    height: '100%',
  },
  marginLeft: {
    marginLeft: 31,
  },
  spinner: {
    margin: 'auto',
    marginTop: '20px',
  },
  previewPage: {
    display: 'none',
  },
  '& .pdfLoaded .previewPage': {
    display: 'block',
  },
};

class ReportPreviewModal extends Component {
  state = {
    reportResponse: '',
    rawResponse: [],
    loadPDF: true,
  };

  onCancel = () => {
    this.props.onSaveAndEmail(); //no params means close
  }

  onSaveToFiles = () => {
    const formData = new FormData();
    const {
      report,
    } = this.props;

    const now = new Date();
    const appendDate = moment(now).format('MMDD');

    formData.append('file', new Blob([this.state.rawResponse], { type: 'application/pdf' }), report.name.replace(/\s/g, '') + appendDate + ".pdf");

    this.props.onSaveAndEmail(false, formData);
  }

  onSaveAndEmail = () => {
    const formData = new FormData();
    const {
      report,
    } = this.props;

    const now = new Date();
    const appendDate = moment(now).format('MMDD');

    formData.append('file', new Blob([this.state.rawResponse], { type: 'application/pdf' }), report.name.replace(/\s/g, '') + appendDate + ".pdf");

    this.props.onSaveAndEmail(true, formData);
  }

  saveBarExtraButtons = () => {
    return (<PrimaryButton
      onClick={this.onSaveAndEmail}
      classes={{ root: this.props.classes.marginLeft }}
    >
      Attach &amp; Email
    </PrimaryButton>);
  }

  fetchReport = () => {
    const {
      report,
      params,
      user,
    } = this.props;

    if (report && user && !this.state.responseText) {

      const url = report && reportPdfUrl(report.url, params);
      const token = user.access_token;
      const headers = { Authorization: `Bearer ${token}` };

      fetch(url, {
        method: 'GET',
        headers,
      }).then(response => {
        response.arrayBuffer().then(buffer => {
          var base64Flag = 'data:application/pdf;base64,';
          var pdfSrc = this.arrayBufferToBase64(buffer);

          this.setState(
            {
              rawResponse: buffer,
              reportResponse: base64Flag + pdfSrc,
            });
        });
      });
    }
  }

  arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach(b => binary += String.fromCharCode(b));

    return window.btoa(binary);
  }

  componentDidUpdate(prevProps) {
    if (this.props.report && (!prevProps.report || prevProps.report.url !== this.props.report.url || this.props.params !== prevProps.params)) {
      this.fetchReport();
    }
  }

  componentWillUnmount() {
    this.setState(
      {
        reportResponse: '',
      });
  }

  render() {
    const {
      isOpened,
      classes,
    } = this.props;

    const {
      reportResponse,
    } = this.state;

    return (
      <Modal
        isOpened={isOpened}
        dimensions={dimensions}
        onCancel={this.onCancel}
        onSave={this.onSaveToFiles}
        title="Print Preview"
        saveText="Attach to Booking"
        addTitleBottomBorder={true}
        saveBarExtraButtons={this.saveBarExtraButtons}
      >
        {reportResponse &&
          <iframe
            className={`pdf-viewer ${ !this.state.loadPDF ? 'pdfLoaded' : '' }`}
            title="Print Preview"
            src={reportResponse}
            height={'100%'}
            onLoad={() => { this.setState({ loadPDF: false }); }}
          >
          </iframe>}
        {!reportResponse &&
          <CircularProgress className={classes.spinner} size={40} />}
      </Modal >);
  }
}

const mapStateToProps = state => {
  return {
    user: state.oidc.user,
  };
};

const mapDispatchToProps = {
  uploadFile,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ReportPreviewModal));
